.cs-input-toolbox {
  position: absolute;
  bottom: 52px;
  left: 10px;
}

.cs-message-list {
  height: 790px;
}

.cs-input-toolbox {
  width: 99%;
  justify-content: space-between !important;
}
