.side-sheet-chat .cs-input-toolbox {
  position: absolute;
  bottom: 76px;
  left: 366px;
  width: 65px;
  background-color: #fff7ed;
}

.side-sheet-message-list {
  height: 804px !important;
}

.side-sheet-chat {
  overflow-x: hidden;
  border: none;
}

.side-sheet-chat .cs-message-input__content-editor-container {
  min-height: 46px;
  max-height: 46px;
}

.side-sheet-chat .scrollbar-container {
  height: 770px;
}

.side-sheet-chat .cs-message__html-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.side-sheet-chat .cs-message-list {
  background-color: #fff7ed;
}

.side-sheet-chat .cs-message-input {
  border-color: #fdba74;
  background-color: #fff7ed;
}

.side-sheet-chat .cs-message-input__content-editor-wrapper {
  background-color: #fde7c9;
}

.side-sheet-chat .cs-message-input__content-editor {
  background-color: #fde7c9;
}

.side-sheet-chat .cs-button--send {
  color: #fdba74;
}

.side-sheet-chat .cs-message__content {
  background-color: #fde7c9;
}

.side-sheet-chat .cs-button.cs-button--border {
  border-color: #fdba74;
  color: #fdba74;
}

.side-sheet-chat .cs-typing-indicator {
  background-color: #fff7ed;
}

.side-sheet-chat .cs-typing-indicator__text {
  color: #fdba74;
}

.side-sheet-chat .cs-typing-indicator__dot {
  background-color: #fdba74;
}

.side-sheet-chat .ps__thumb-y {
  background-color: #fdba74;
}

.side-sheet-chat .cs-message__avatar {
  filter: invert(59%) sepia(98%) saturate(2526%) hue-rotate(1deg)
    brightness(105%) contrast(104%);
}

.side-sheet-chat .cs-button:hover {
  color: #d97b00;
}

.side-sheet-chat .ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #ffc374;
}

.side-sheet-chat .ps .ps__rail-y:hover {
  background-color: #fff7ed;
}
